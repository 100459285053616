import React, { useState, useRef, useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Link } from "react-router-dom";
import { EditNote, InfoOutlined, MusicNote, Download } from "@mui/icons-material";
import moment from "moment";
import TableComponent from "../../Atoms/TableComponent";
import GetData from "../../API/GetData";
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // Import jsPDF autoTable for table creation

export default function Index(props) {
  const { setprofileInfo, setpatientId, setvisit_Id, setthroughRout } = props;
  const [allPatientsList, setAllPatientsList] = useState([]);
  const [fileCount, setfileCount] = useState(0);
  const [coughingCount, setcoughingCount] = useState(0);
  const [breathingCount, setbreathingCount] = useState(0);
  const [testReportCount, settestReportCount] = useState(0);
  const [formCount, setformCount] = useState(0);
  const [consentFormCount, setconsentFormCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const authToken = localStorage.getItem("accessToken");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL || "http://192.168.22.227:8080/"}info/patient/get?stream=true`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error(`Request failed with status ${response.status}`);
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          buffer += decoder.decode(value, { stream: true });
          let start = 0;
          let end = buffer.indexOf("\n", start);
          while (end !== -1) {
            const jsonString = buffer.substring(start, end).trim();
            if (jsonString) {
              try {
                const newPatient = JSON.parse(jsonString);
                setAllPatientsList((prevList) => [...prevList, newPatient]);
                // Set p_id from the patient data
                
              } catch (error) {
                console.error("Error parsing JSON:", error, jsonString);
              }
            }
            start = end + 1;
            end = buffer.indexOf("\n", start);
          }
          buffer = buffer.substring(start);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();


    
  }, [authToken]);

  function fullDate(params) {
    if (params.value) {
      const date = new Date(params.value);
      return moment(date).format("DD-MMM-YY");
    } else return "-";
  }

  const handleDownload = (rowData) => {
    const dataStr = JSON.stringify(rowData, null, 2);
    const blob = new Blob([dataStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `record_${rowData.serial_number || "data"}.json`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Table title
    doc.text("TB Patient Data Report", 14, 10);

    // Add the table
    doc.autoTable({
      head: [
        ["S.No", "Name", "Status", "Diagnosed On", "Age", "Cough Frequency", "Blood in Cough", "Fever Status", "Fever Level", "Weight", "Weight Loss"],
      ],
      body: allPatientsList.map((patient) => [
        patient.serial_number,
        patient.name,
        patient.patient_type,
        patient.initial_diagnose,
        patient.age,
        patient.cough_frq,
        patient.coughing_blood,
        patient.fever,
        patient.fever_level,
        patient.weight,
        patient.weight_loss,
      ]),
      startY: 20,
      theme: "grid",
      headStyles: {
        fillColor: [63, 81, 181],
        textColor: [255, 255, 255],
        fontSize: 10,
      },
      bodyStyles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        lineColor: [200, 200, 200],
      },
      styles: {
        font: "helvetica",
        halign: "center",
        valign: "middle",
      },
    });

    // Add page numbers
    const totalPages = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${totalPages}`, 180, 290);

    // Save the PDF
    doc.save("patient_data_report.pdf");
  };

  const columnDefs = [
    // {
    //   headerName: "",
    //   field: "name",
    //   sortable: false,
    //   pinned: "left",
    //   minWidth: 120,
    //   maxWidth: 120,
    //   cellRenderer: function (params) {
    //     if (params.data && params.data.hasOwnProperty("p_id")) {
    //       return (
    //         <div className="position-relative">
    //           <div>
    //             {/* Edit Note: This should be shown for all roles */}
    //             <Link to={`/Form/${params.data.visit_id}`}>
    //               <EditNote className="img-placement fs-1" />
    //             </Link>
    
    //             {/* Music Note: Show for all roles except Ward Boy */}
    //             {localStorage.getItem("role") !== process.env.REACT_APP_WARD_BOY && (
    //               <Link
    //                 to={`/Analysis`}
    //                 onClick={() => {
    //                   setpatientId(params.data.p_id);
    //                   localStorage.setItem("patient_ID", `${params.data.p_id}`);
    //                 }}
    //                 className="ms-2"
    //               >
    //                 <MusicNote className="img-placement2 fs-1" />
    //               </Link>
    //             )}
    
    //             {/* Info Icon: Always show */}
    //             <InfoOutlined
    //               className="img-placement3 text-primary fs-1"
    //               onClick={() => onCheckFormStatus(params)}
    //             />
    //           </div>
    //         </div>
    //       );
    //     } else return null;
    //   }
    // },
    
    {
      headerName: "",
      field: "name",
      sortable: false,
      pinned: "left",
      minWidth: 120,
      maxWidth: 120,
      cellRenderer: function (params) {
        if (true) {
          return (
            <div className="position-relative">
              <div>
              {/* {console.log("visit id is ------>>>>",params.data.visit_id)} */}

                {/* Show EditNote icon for everyone */}
                {/* <Link to={`/Form/${params.data.visit_id}`}>
                  <EditNote className="img-placement fs-1" />
                </Link> */}

    {/* {console.log("visit id is ++++>>>>",params.data.visit_id)}

                {/* Show MusicNote icon for everyone */}
                <Link
                  to={`/Analysis`}
                  onClick={() => {
                    setpatientId(params.data.p_id);
                    localStorage.setItem("patient_ID", `${params.data.p_id}`);
                  }}
                  className="ms-2"
                >
                  <MusicNote className="img-placement2 fs-1" />
                </Link>
    
                {/* Always show InfoOutlined icon for everyone */}
                <InfoOutlined
                  className="img-placement3 text-primary fs-1"
                  onClick={() => onCheckFormStatus(params)}
                />
              </div>
            </div>
          );
        } else return null;
      },
    },
    { headerName: "S.No", field: "serial_number", sortable: true, maxWidth: 130 },
    { headerName: "Status", field: "patient_type", filter: "agSetColumnFilter", enableRowGroup: true },
    { headerName: "Diagnosed On", field: "initial_diagnose", maxWidth: 150, filter: "agDateColumnFilter", cellRenderer: fullDate, enableRowGroup: true },
    { headerName: "Age", field: "age", filter: "agSetColumnFilter", enableRowGroup: true },
    { headerName: "Cough Frequency", field: "cough_frq", filter: "agSetColumnFilter", enableRowGroup: true },
    { headerName: "Blood in Cough", field: "coughing_blood", filter: "agSetColumnFilter", enableRowGroup: true },
    { headerName: "Fever Status", field: "fever", filter: "agSetColumnFilter", enableRowGroup: true },
    { headerName: "Fever Level", field: "fever_level", filter: "agSetColumnFilter", enableRowGroup: true },
    { headerName: "Fever For", field: "fever_for", filter: "agSetColumnFilter", enableRowGroup: true },
    { headerName: "Fever Time", field: "fever_time", filter: "agSetColumnFilter", enableRowGroup: true },
    { headerName: "Weight", field: "weight", filter: "agNumberColumnFilter" },
    { headerName: "Weight Loss", field: "weight_loss", filter: "agSetColumnFilter", enableRowGroup: true },
    {
      headerName: "Actions",
      field: "actions",
      pinned: "right",
      maxWidth: 120,
      cellRenderer: (params) => (
        <div style={{ display: "flex-end", alignItems: "center", gap: "8px" }}>
          <Download style={{ cursor: "pointer", color: "#007bff" }} onClick={() => handleDownload(params.data)} />
        </div>
      ),
    },
  ];

  const onCheckFormStatus = (params) => {
    const visit_id = params.data.visit_id;
    console.log("visit id ==>>", visit_id)
    setIsCounting(true);
    if (visit_id) {
      const res = GetData.GetRecordingsById(visit_id);
      res.then((value) => {
        if (value !== undefined) {
          const data = value.data;
          const getCountByType = (type) => data?.filter((label) => label.type === type).length;
          setfileCount(getCountByType("file"));
          setcoughingCount(getCountByType("coughing"));
          setbreathingCount(getCountByType("breathing"));
          settestReportCount(getCountByType("test-report"));
          setformCount(getCountByType("form"));
          setconsentFormCount(getCountByType("consent-form"));
          setIsCounting(false);
        }
      });
    }
  };

  

  return (
    <div className="row container mx-auto pt-3 themeContainer">
      <h1 className="mt-3 mb-0 text-blue">All Participants</h1>
      <button
        className="btn btn-primary mb-3 mx-auto"
        onClick={downloadPDF}
        style={{
          width: "20%",
          float: "right",
          fontSize: "1.2rem",  // Adjust as needed for size
          padding: "15px 7px", // Adjust as needed for padding
          position: "absolute",
          right: "auto"
        }}
      >
        Download PDF
      </button>
      <TableComponent
        value={"allPatientsList"}
        Data={allPatientsList}
        columnDefs={columnDefs}
        floatingFilter={true}
        fileCount={fileCount}
        coughingCount={coughingCount}
        breathingCount={breathingCount}
        testReportCount={testReportCount}
        consentFormCount={consentFormCount}
        formCount={formCount}
        isCounting={isCounting}
        sendingReq={false}
        details={true}
      />
    </div>
  );
}


// import React, { useState, useRef, useEffect, useMemo } from "react";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
// import "ag-grid-enterprise";
// import { Link } from "react-router-dom";
// import { EditNote, InfoOutlined, MusicNote } from "@mui/icons-material";
// import moment from "moment";
// import TableComponent from "../../Atoms/TableComponent";
// import GetData from "../../API/GetData";
// import { set } from "date-fns";

// export default function Index(props) {
//   const { setprofileInfo, setpatientId, setvisit_Id, setthroughRout } = props;
//   const [columnHide, setcolumnHide] = useState(false);
//   const [breathingCount, setbreathingCount] = useState(0);
//   const [coughingCount, setcoughingCount] = useState(0);
//   const [testReportCount, settestReportCount] = useState(0);
//   const [formCount, setformCount] = useState(0);
//   const [fileCount, setfileCount] = useState(0);
//   const [allPatientsList, setallPatientsList] = useState([]);
//   const [sendingReq, setSendingReq] = useState(false);
//   const [isCounting, setIsCounting] = useState(false);

//   useEffect(() => {
//     if (localStorage.getItem("role") === process.env.REACT_APP_ANALYST) {
//       setcolumnHide(false);
//     } else if (localStorage.getItem("role") === process.env.REACT_APP_ADMIN) {
//       setcolumnHide(false);
//     } else setcolumnHide(true);
//     setSendingReq(true);
//     GetData.AllPatientsList().then((res) => {
//       res = res.data;
//       setallPatientsList(res);
//       setSendingReq(false);
//     });
//   }, []);

//   const listDateFilters = (startDate, endDate, gridApi, getFilterType) => {
//     var dateFilterComponent = gridApi.api.getFilterInstance("dob");
//     dateFilterComponent.setModel({
//       type: getFilterType(),
//       inRange: true,
//       dateFrom: startDate,
//       dateTo: endDate,
//     });
//     var dateFilterComponent1 = gridApi.api.getFilterInstance("created_at");
//     dateFilterComponent1.setModel({
//       type: getFilterType(),
//       inRange: true,
//       dateFrom: startDate,
//       dateTo: endDate,
//     });
//   };

//   function fullDate(params) {
//     if (params.value) {
//       const date = new Date(params.value);
//       const yyyy = date.getFullYear();
//       const yy = yyyy.toString();
//       const y = yy?.slice(2, 4);
//       let mm = date.toLocaleString("default", { month: "short" });
//       let dd = date.getDate();
//       if (dd < 10) dd = "0" + dd;
//       return dd + "-" + mm + "-" + y;
//     } else return "-";
//   }

//   const onCheckFormStatus = (params) => {
//     const visit_id = params.data.visit_id;
//     setIsCounting(true);
//     if (visit_id) {
//       const res = GetData.GetRecordingsById(visit_id);
//       res.then((value) => {
//         if (value !== undefined) {
//           const data = value.data;

//           const getCountByType = (type) => {
//             return data?.filter((label) => label.type === type).length;
//           };

//           const fileCount = getCountByType("file");
//           const coughingCount = getCountByType("coughing");
//           const breathingCount = getCountByType("breathing");
//           const formCount = getCountByType("form");
//           const testReportCount = getCountByType("test-report");
//           setIsCounting(false);
//           setfileCount(fileCount);
//           setcoughingCount(coughingCount);
//           setbreathingCount(breathingCount);
//           settestReportCount(testReportCount);
//           setformCount(formCount);
//         }
//       });
//     }
//   };

//   const columnDefs = [
//     {
//       headerName: "",
//       field: "name",
//       sortable: false,
//       pinned: "left",
//       minWidth: 120,
//       maxWidth: 120,
//       cellRenderer: function (params) {
//         if (params.data && params.data.hasOwnProperty("p_id")) {
//           return (
//             <div className="position-relative">
//               <div className="">
//                 {localStorage.getItem("role") ===
//                   process.env.REACT_APP_VIEWER ||
//                 localStorage.getItem("role") ===
//                   process.env.REACT_APP_DOCTOR ? null : (
//                   <Link to={`/Form/${params.data.visit_id}`}>
//                     <EditNote className="img-placement fs-1" />
//                   </Link>
//                 )}
//                 {localStorage.getItem("role") ===
//                 process.env.REACT_APP_WARD_BOY ? null : (
//                   <Link
//                     to={`/Analysis`}
//                     onClick={() => {
//                       setpatientId(params.data.p_id);
//                       localStorage.setItem("patient_ID", `${params.data.p_id}`);
//                     }}
//                     className="ms-2"
//                   >
//                     <MusicNote className="img-placement2 fs-1" />
//                   </Link>
//                 )}
//                 <InfoOutlined
//                   className="img-placement3 text-primary fs-1"
//                   onClick={() => onCheckFormStatus(params)}
//                 />
//               </div>
//             </div>
//           );
//         } else {
//           return null;
//         }
//       },
//     },
//     {
//       headerName: "S.No",
//       field: "serial_number",
//       sortable: true,
//       maxWidth: 130,
//     },
//     {
//       headerName:
//         localStorage.getItem("role") === process.env.REACT_APP_ANALYST ||
//         localStorage.getItem("role") === process.env.REACT_APP_ADMIN ||
//         localStorage.getItem("role") === process.env.REACT_APP_WARD_BOY
//           ? "Name"
//           : columnHide && "User Name",
//       field:
//         localStorage.getItem("role") === process.env.REACT_APP_ANALYST ||
//         localStorage.getItem("role") === process.env.REACT_APP_ADMIN ||
//         localStorage.getItem("role") === process.env.REACT_APP_WARD_BOY
//           ? "name"
//           : columnHide && "username",
//       sortable: true,
//     },
//     {
//       headerName: "Mobile",
//       field: "phone",
//       maxWidth: 130,
//       filter: true,
//     },
//     {
//       headerName: "Visit Id",
//       field: "visit_id",
//       maxWidth: 130,
//       filter: true,
//       columnHide: columnHide,
//     },
//     {
//       headerName: "Status",
//       field: "patient_type",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//     },
//     {
//       headerName: "Medical Condition",
//       field: "tb_type",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//     },
//     {
//       headerName: "Diagnosed On",
//       field: "initial_diagnose",
//       maxWidth: 150,
//       filter: "agDateColumnFilter",
//       filterParams: {
//         filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
//         inRangeInclusive: true,
//         comparator: function (filterLocalDateAtMidnight, cellValue) {
//           var dateAsString = moment(cellValue).format("DD/MM/YYYY");
//           var dateParts = dateAsString.split("/");
//           var cellDate = new Date(
//             Number(dateParts[2]),
//             Number(dateParts[1]) - 1,
//             Number(dateParts[0])
//           );

//           if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
//             return 0;
//           }

//           if (cellDate < filterLocalDateAtMidnight) {
//             return -1;
//           }

//           if (cellDate > filterLocalDateAtMidnight) {
//             return 1;
//           }
//         },
//         buttons: ["clear", "reset", "apply"],
//       },
//       cellRenderer: fullDate,
//       enableRowGroup: true,
//     },
//     {
//       headerName: "Registered At",
//       field: "created_at",
//       filter: "agDateColumnFilter",
//       filterParams: {
//         filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
//         inRangeInclusive: true,
//         comparator: function (filterLocalDateAtMidnight, cellValue) {
//           var dateAsString = moment(cellValue).format("DD/MM/YYYY");
//           var dateParts = dateAsString.split("/");
//           var cellDate = new Date(
//             Number(dateParts[2]),
//             Number(dateParts[1]) - 1,
//             Number(dateParts[0])
//           );

//           if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
//             return 0;
//           }

//           if (cellDate < filterLocalDateAtMidnight) {
//             return -1;
//           }

//           if (cellDate > filterLocalDateAtMidnight) {
//             return 1;
//           }
//         },
//         buttons: ["clear", "reset", "apply"],
//       },
//       cellRenderer: fullDate,
//       enableRowGroup: true,
//     },
//     {
//       field: "p_id",
//       filter: "agNumberColumnFilter",
//       minWidth: 325,
//     },
//     {
//       headerName: "Age",
//       field: "age",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//       hide: columnHide,
//     },
//     {
//       headerName: "Cough Frequency",
//       field: "cough_frq",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//       hide: columnHide,
//     },
//     {
//       headerName: "Blood in Cough",
//       field: "coughing_blood",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//       hide: columnHide,
//     },
//     // {
//     //   headerName: "Education Status",
//     //   field: "education_level",
//     //   filter: "agSetColumnFilter",
//     //   enableRowGroup: true,
//     //   hide: columnHide,
//     // },
//     {
//       headerName: "Fever Status",
//       field: "fever",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//       hide: columnHide,
//     },
//     {
//       headerName: "Fever Level",
//       field: "fever_level",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//       hide: columnHide,
//     },
//     {
//       headerName: "Fever For",
//       field: "fever_for",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//       hide: columnHide,
//     },
//     {
//       headerName: "Fever Time",
//       field: "fever_time",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//       hide: columnHide,
//     },
//     {
//       headerName: "Weight",
//       field: "weight",
//       filter: "agNumberColumnFilter",
//       hide: columnHide,
//     },
//     {
//       headerName: "Weight Loss",
//       field: "weight_loss",
//       filter: "agSetColumnFilter",
//       enableRowGroup: true,
//       hide: columnHide,
//     },
//   ];

//   const [search, setSearch] = useState(false);
//   const inputRef = useRef(null);

//   useEffect(() => {
//     if (search) {
//       inputRef.current.focus();
//     }
//   }, [search]);

//   return (
//     <div className="row container mx-auto pt-3">
//       <h1 className="mt-3 mb-0 text-blue">All Participants</h1>
//       <TableComponent
//         value={"allPatientsList"}
//         Data={allPatientsList}
//         columnDefs={columnDefs}
//         floatingFilter={true}
//         datefilters={listDateFilters}
//         fileCount={fileCount}
//         coughingCount={coughingCount}
//         breathingCount={breathingCount}
//         testReportCount={testReportCount}
//         formCount={formCount}
//         isCounting={isCounting}
//         sendingReq={sendingReq}
//         details={true}
//       />
//     </div>
//   );
// }
