import { Button, ButtonBase, Skeleton } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";

export default function TicketsCard(props) {
  const {
    heading,
    bgColor,
    sampleValue,
    participantValue,
    txtColor,
    key,
    icon,
    brdrColor,
    ticketsDatafinal,
    setmedicalStatus,
    medicalStatus,
    facilityId,
  } = props;
  return ticketsDatafinal ? (
    sampleValue && participantValue ? (
      <ButtonBase
        onClick={(e) => {
          setmedicalStatus(heading);
        }}
        // disabled={facilityId === 0 ? false : true}
        className={`ticket-cards row ${
           medicalStatus === heading
            ? "selected-medicalStatus"
            : null
        }`}
        key={key}
        style={{ backgroundColor: bgColor }}
      >
        <div
          className="col-12 h-53 d-flex flex-column text-center fs-24 poppins-Med"
          style={{
            color: txtColor,
            borderBottom: `2px solid ${brdrColor}`,
          }}
        >
          <h2 className="my-auto">
            {icon && (
              <img
                src={icon}
                className="ticket-icon me-2"
                style={{ color: txtColor }}
              />
            )}
            TB Data
          </h2> 
        </div>
        <div className="d-inline-flex w-100">
          <div
            className="w-50 d-flex flex-column text-center pt-2 pb-0"
            style={{
              color: txtColor,
              borderRight: `2px solid ${brdrColor}`,
            }}
          >
            <h4 className="text-dark pt-2"> Participants</h4>
            <Fade delay={1e1} cascade damping={1e-1}>
              <h4 className=" fw-bold">
                {participantValue ? (
                  participantValue
                ) : (
                  <Skeleton width={40} height={20} className="mx-auto" />
                )}
              </h4>
            </Fade>
          </div>
          <div
            className="w-50 d-flex flex-column text-center pt-2"
            style={{ color: txtColor }}
          >
            <h4 className="text-dark pt-2"> Samples</h4>
            <Fade delay={1e1} cascade damping={1e-1}>
              <h4 className=" fw-bold">
                {sampleValue ? (
                  sampleValue
                ) : (
                  <Skeleton width={40} height={20} className="mx-auto" />
                )}
              </h4>
            </Fade>
          </div>
        </div>
      </ButtonBase>
    ) : null
  ) : (
    <Skeleton />
  );
}
