import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Fade } from "react-awesome-reveal";
import HomePageApi from "../API/HomePageApi";
import { Skeleton } from "@mui/material";

export default function AgeBarChart(props) {
  const {
    cardHeight,
    id,
    chartHeight,
    heading,
    y_title,
    x_title,
    ApiData,
    facilityId,
    startDate,
    endDate,
    stackedChart,
    userRole,
    multiselectfeature,
    setmultiselectFeature,
    onlyFacility,
    medicalStatus,
  } = props;

  const [AgeStatsApi, setAgeStatsApi] = useState();
  const [seriesData, setSeriesData] = useState([]);
  const [xCategories, setXCategories] = useState([
    "10-20",
    "21-30",
    "31-40",
    "41-50",
    "51-60",
    "60+",
  ]);
  const [colColors, setcolColors] = useState([
    "#EE7E2D",
    "#2DCDD4",
    "#FFC000",
    "#52B66D",
    "#d5239e",
    "#5B9BD5",
    "#3bcfb1",
    "#49BEFF",
    "#92cf3b",
  ]);

  useEffect(() => {
    HomePageApi.GetAgeFeaturesStats(
      facilityId,
      startDate,
      endDate,
      medicalStatus,
      multiselectfeature
    ).then((res) => {
      res = res.data;
      const apiData = res.map((each) => {
        each.label = each.label?.replace(" ", "");
        if (
          each.label?.charAt(0) !== each.label?.charAt(0).toUpperCase() ||
          each.label === null
        ) {
          each.label = "NotLabelled";
          return each;
        } else if (each.label === "Others") {
          each.label = "-" + each.label;
          return each;
        } else {
          return each;
        }
      });
      setAgeStatsApi(res);

      const uniqueLabels = Array.from(
        new Set(apiData?.map((item) => item.label || "NotLabelled"))
      );

      const seriesDataArray = uniqueLabels.sort()?.map((label) => {
        const data = apiData
          ?.filter((item) => item.label === label)
          ?.map((item) => item.count);
        return {
          name: label,
          data: data,
        };
      });
      setSeriesData(seriesDataArray.slice(0, 7));
    });
  }, [facilityId, startDate, endDate, medicalStatus, multiselectfeature]);


  const state = {
    series: seriesData,
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: { show: false },
        stacked: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          dataLabels: {
            position: "top",
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "top",
        offsetY: onlyFacility ? 0 : -30,
        horizontalAlign: "right",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        markers: {
          radius: 10,
          offsetY: 2,
        },
      },
      tooltip: {
        enabled: true,
      },
      title: {
        text: onlyFacility ? "" : heading,
        align: "left",
        style: {
          fontSize: "24px",
          fontWeight: "700",
          fontFamily: "poppins",
          color: "#000000",
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: "#FFFFFF",
        width: 3,
        dashArray: 0,
      },
      dataLabels: {
        enabled: true,
        total: {
          enabled: true,
          style: {
            fontSize: "10px",
            fontFamily: "Poppins",
            colors: ["black"],
          },
        },
        style: {
          fontSize: "10px",
          fontFamily: "Poppins",
          colors: ["black"],
        },
      },
      colors: colColors,
      xaxis: {
        categories: xCategories,
        labels: {
          show: true,
        },
        title: {
          text: x_title,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          tickAmount: 13,
          count: 13,
        },
        title: {
          text: y_title,
        },
      },
    },
  };

  return (
    <div className="position-relative" key={id} style={{ height: cardHeight }}>
      {onlyFacility ? (
        <p
          className="poppins-Med position-absolute"
          style={{
            paddingLeft: "5px",
            fontSize: "24px",
            fontWeight: "700",
            fontFamily: "poppins",
            color: "#000000",
          }}
        >
          {`${multiselectfeature.toUpperCase().replace("_", " ")} Stats by Age`}{" "}
        </p>
      ) : null}
      {onlyFacility && (
        <div className="form-group col-4 mb-3 ms-auto text-end">
          <select
            className="form-control form-control-lg"
            id="feature"
            onChange={(e) => {
              setmultiselectFeature(e.target.value);
            }}
          >
            <option value="gender">Gender</option>
            <option value="tb_type">TB type</option>
            <option value="marital_status">Marital status</option>
            <option value="ventilated">Ventilated room</option>
            <option value="smoking_history">Smoking history</option>
            <option value="weight_loss">Weight loss</option>
            <option value="fever">Fever</option>
            <option value="coughing_blood">Coughing blood</option>
            <option value="loss_appetite">Loss of appetite</option>
            <option value="patient_type">Patient Type</option>
          </select>
        </div>
      )}
      {/* Gender Stats By Age */}
      <div id="chart">
        <Fade delay={1e1}>
          <ReactApexChart
            options={state.options}
            series={state.series}
            categories={state.xaxis}
            type="bar"
            height={chartHeight}
          />
        </Fade>
      </div>
    </div>
  );
}
// import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
// import { Fade } from "react-awesome-reveal";
// import HomePageApi from "../API/HomePageApi";
// import { Skeleton } from "@mui/material";

// export default function AgeBarChart(props) {
//   const {
//     cardHeight,
//     id,
//     chartHeight,
//     heading,
//     y_title,
//     x_title,
//     ApiData,
//     facilityId,
//     startDate,
//     endDate,
//     stackedChart,
//     userRole,
//     multiselectfeature,
//     setmultiselectFeature,
//     onlyFacility,
//     medicalStatus,
//   } = props;

//   const [AgeStatsApi, setAgeStatsApi] = useState();
//   const [seriesData, setSeriesData] = useState([]);
//   const [xCategories, setXCategories] = useState([
//     "10-20",
//     "21-30",
//     "31-40",
//     "41-50",
//     "51-60",
//     "60+",
//   ]);
//   const [colColors, setcolColors] = useState([
//     "#EE7E2D",
//     "#2DCDD4",
//     "#FFC000",
//     "#52B66D",
//     "#d5239e",
//     "#5B9BD5",
//     "#3bcfb1",
//     "#49BEFF",
//     "#92cf3b",
//   ]);

//   useEffect(() => {
//     HomePageApi.GetAgeFeaturesStats(
//       facilityId,
//       startDate,
//       endDate,
//       medicalStatus,
//       multiselectfeature
//     ).then((res) => {
//       res = res.data;
//       const apiData = res.map((each) => {
//         each.label = each.label?.replace(" ", "");
//         if (
//           each.label?.charAt(0) !== each.label?.charAt(0).toUpperCase() ||
//           each.label === null
//         ) {
//           each.label = "NotLabelled";
//           return each;
//         } else if (each.label === "Others" || each.label === "New") {
//           each.label = "-" + each.label;
//           return each;
//         } else {
//           return each;
//         }
//       });
//       setAgeStatsApi(res);

//       const uniqueLabels = Array.from(
//         new Set(apiData?.map((item) => item.label || "NotLabelled"))
//       );

//       const seriesDataArray = uniqueLabels.sort()?.map((label) => {
//         const data = apiData
//           ?.filter((item) => item.label === label)
//           ?.map((item) => item.count);
//         return {
//           name: label,
//           data: data,
//         };
//       });
//       setSeriesData(seriesDataArray.slice(0, 7));
//     });
//   }, [facilityId, startDate, endDate, medicalStatus, multiselectfeature]);


//   const state = {
//     series: seriesData,
//     options: {
//       chart: {
//         height: 350,
//         type: "bar",
//         toolbar: { show: false },
//         stacked: true,
//       },
//       plotOptions: {
//         bar: {
//           borderRadius: 3,
//           dataLabels: {
//             position: "top",
//           },
//         },
//       },
//       legend: {
//         show: true,
//         showForSingleSeries: false,
//         showForNullSeries: true,
//         showForZeroSeries: true,
//         position: "top",
//         offsetY: onlyFacility ? 0 : -30,
//         horizontalAlign: "right",
//         fontSize: "12px",
//         fontFamily: "Poppins",
//         fontWeight: 400,
//         markers: {
//           radius: 10,
//           offsetY: 2,
//         },
//       },
//       tooltip: {
//         enabled: true,
//       },
//       title: {
//         text: onlyFacility ? "" : heading,
//         align: "left",
//         style: {
//           fontSize: "24px",
//           fontWeight: "700",
//           fontFamily: "poppins",
//           color: "#000000",
//         },
//       },
//       stroke: {
//         show: true,
//         curve: "smooth",
//         lineCap: "butt",
//         colors: "#FFFFFF",
//         width: 3,
//         dashArray: 0,
//       },
//       dataLabels: {
//         enabled: true,
//         total: {
//           enabled: true,
//           style: {
//             fontSize: "10px",
//             fontFamily: "Poppins",
//             colors: ["black"],
//           },
//         },
//         style: {
//           fontSize: "10px",
//           fontFamily: "Poppins",
//           colors: ["black"],
//         },
//       },
//       colors: colColors,
//       xaxis: {
//         categories: xCategories,
//         labels: {
//           show: true,
//         },
//         title: {
//           text: x_title,
//         },
//       },
//       yaxis: {
//         axisBorder: {
//           show: false,
//         },
//         title: {
//           text: y_title,
//         },
//       },
//     },
//   };

//   return (
//     <div className="position-relative" key={id} style={{ height: cardHeight }}>
//       {onlyFacility ? (
//         <p
//           className="poppins-Med position-absolute"
//           style={{
//             paddingLeft: "5px",
//             fontSize: "24px",
//             fontWeight: "700",
//             fontFamily: "poppins",
//             color: "#000000",
//           }}
//         >
//           {`${multiselectfeature.toUpperCase().replace("_", " ")} Stats by Age`}{" "}
//         </p>
//       ) : null}
//       {onlyFacility && (
//         <div className="form-group col-4 mb-3 ms-auto text-end">
//           <select
//             className="form-control form-control-lg"
//             id="feature"
//             onChange={(e) => {
//               setmultiselectFeature(e.target.value);
//             }}
//           >
//             <option value="gender">Gender</option>
//             <option value="tb_type">TB type</option>
//             <option value="marital_status">Marital status</option>
//             <option value="ventilated">Ventilated room</option>
//             <option value="smoking_history">Smoking history</option>
//             <option value="weight_loss">Weight loss</option>
//             <option value="fever">Fever</option>
//             <option value="coughing_blood">Coughing blood</option>
//             <option value="loss_appetite">Loss of appetite</option>
//             <option value="patient_type">Patient Type</option>
//           </select>
//         </div>
//       )}
//       <div id="chart">
//         <Fade delay={1e1}>
//           <ReactApexChart
//             options={state.options}
//             series={state.series}
//             categories={state.xaxis}
//             type="bar"
//             height={chartHeight}
//           />
//         </Fade>
//       </div>
//     </div>
//   );
// }
