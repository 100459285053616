import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home2 from "../Routs/Home2";
import UsersTable from "../Routs/UsersTable";
import Form from "../Routs/Form";
import Approval from "../Routs/Approval";
import Validation from "../Routs/Validation";
import UserManagement from "../Routs/UserManagement";
import Annotate from "../Routs/Annotate";
import CheckList from "../Routs/CheckList";
import Navbar from "../Routs/Navbar";
import { useState } from "react";
import CovidData from "../Routs/CovidData";

export default function Index() {
  const [profileInfo, setprofileInfo] = useState([]);
  const [patientId, setpatientId] = useState();
  const [visit_Id, setvisit_Id] = useState();

  return (
    <>
      <Navbar />
      {localStorage.getItem("role") === process.env.REACT_APP_VIEWER && (
        <Routes>
          <Route path="home" element={<Home2 />} />
          <Route path="covid-data" element={<CovidData />} />
          <Route
            path="UsersTable"
            element={
              <UsersTable
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
                setpatientId={setpatientId}
                setvisit_Id={setvisit_Id}
              />
            }
          />
          <Route
            path="Analysis"
            element={
              <Annotate
                patientId={patientId}
                setvisit_Id={setvisit_Id}
                visit_Id={visit_Id}
                setpatientId={setpatientId}
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
              />
            }
          />
          {/* <Route
            path="checkList"
            element={<CheckList setpatientId={setpatientId} />}
          /> */}
        </Routes>
      )}
      {localStorage.getItem("role") === process.env.REACT_APP_DOCTOR && (
        <Routes>
          <Route
            path="Analysis"
            element={
              <Annotate
                patientId={patientId}
                setvisit_Id={setvisit_Id}
                visit_Id={visit_Id}
                setpatientId={setpatientId}
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
              />
            }
          />
          <Route
            path="checkList"
            element={<CheckList setpatientId={setpatientId} />}
          />
          <Route path="/home" element={<Navigate to="/Analysis" replace />} />
        </Routes>
      )}

      {localStorage.getItem("role") === process.env.REACT_APP_ADMIN && (
        <Routes>
          <Route path="home" element={<Home2 />} />
          <Route path="form/:visit_id" element={<Form />} />
          <Route path="onfirstupdated/:visit_id" element={<Form />} />
          <Route path="updatedform/:visit_id" element={<Form />} />
          <Route
            path="UsersTable"
            element={
              <UsersTable
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
                setpatientId={setpatientId}
                setvisit_Id={setvisit_Id}
              />
            }
          />
          <Route path="Approval" element={<Approval />} />
          <Route path="UserManagement" element={<UserManagement />} />
          <Route
            path="Analysis"
            element={
              <Annotate
                patientId={patientId}
                setvisit_Id={setvisit_Id}
                visit_Id={visit_Id}
                setpatientId={setpatientId}
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
              />
            }
          />
          <Route path="Validation" element={<Validation />} />
          <Route
            path="checkList"
            element={<CheckList setpatientId={setpatientId} />}
          />
        </Routes>
      )}
      {localStorage.getItem("role") === process.env.REACT_APP_ANALYST && (
        <Routes>
          <Route path="home" element={<Home2 />} />
          <Route path="form/:visit_id" element={<Form />} />
          <Route path="onfirstupdated/:visit_id" element={<Form />} />
          <Route path="updatedform/:visit_id" element={<Form />} />
          <Route path="Approval" element={<Approval />} />
          <Route path="Validation" element={<Validation />} />
          <Route
            path="UsersTable"
            element={
              <UsersTable
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
                setpatientId={setpatientId}
                setvisit_Id={setvisit_Id}
              />
            }
          />
          <Route
            path="Analysis"
            element={
              <Annotate
                patientId={patientId}
                setvisit_Id={setvisit_Id}
                visit_Id={visit_Id}
                setpatientId={setpatientId}
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
              />
            }
          />
          <Route
            path="checkList"
            element={<CheckList setpatientId={setpatientId} />}
          />
        </Routes>
      )}
      {localStorage.getItem("role") === process.env.REACT_APP_WARD_BOY && (
        <Routes>
          <Route path="form/:visit_id" element={<Form />} />
          <Route
            path="UsersTable"
            element={
              <UsersTable
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
                setpatientId={setpatientId}
                setvisit_Id={setvisit_Id}
              />
            }
          />
          <Route path="/home" element={<Navigate to="/UsersTable" replace />} />
          <Route
            path="checkList"
            element={<CheckList setpatientId={setpatientId} />}
          />
        </Routes>
      )}
    </>
  );
}
