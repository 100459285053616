import axios from "axios";
// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.baseURL = "http://192.168.22.161:8080";
// axios.defaults.baseURL = "https://dct-stage.ai4lyf.com/";
// axios.defaults.baseURL = "http://192.168.22.148:8080/";
// axios.defaults.baseURL = "https://dctapi.ai4lyf.com/";
axios.defaults.baseURL = process.env.REACT_APP_API_URL || " https://dctapi.ai4lyf.com/";
// axios.defaults.baseURL = "http://192.168.22.227:8080/";

const authToken = localStorage.getItem("accessToken");
axios.defaults.headers.post["Authorization"] = `Bearer ${authToken}`;
axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
class Account {
  constructor() {
    this.result = [];
    this.user = null;
  }

  LoginApi = (phone, password) => {
    const jsonData = {
      phone: phone,
      password: password,
    };
    const res = async () => {
      const resp = await axios
        .post(`/user/login`, jsonData)
        .catch(function (error) {
          console.log(error);
        });
      return resp;
    };
    return res();
  };
}
export default new Account();
