import { EditNote, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
  Snackbar,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import GetData from "../API/GetData";
import { Link } from "react-router-dom";

export default function ProfileCard(props) {
  const {
    setprofileInfo,
    profileInfo,
    userRole,
    setVisit_Id,
    visitsList,
    visit_Id,
  } = props;
  const [open, setOpen] = useState(false);
  const [copiedText, setCopiedText] = useState("");

  const ProfileRow = ({ label, text, copyText }) => (
    <div className="row pe-3 mb-1 border-bottom">
      <div className="col-5 fs-16 fw-700">
        {label}
        {profileInfo.length < 0 ? ":" : null}
      </div>
      {copyText ? (
        <CopyToClipboard text={text} onCopy={handleCopy}>
          <span className="text-end col-7 fs-14 fw-500">{text}</span>
        </CopyToClipboard>
      ) : (
        <div className="text-end col-7 fs-14 fw-500">{text}</div>
      )}
    </div>
  );

  const handleCopy = (text) => {
    setOpen(true);
    setCopiedText(text);
  };

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  return profileInfo ? (
    <>
      <div className="graph-cards mb-4">
        <p className="fs-20 fw-700 text-blue poppins-Med">
          Patient Info ({profileInfo.serial_number})
        </p>
        {visitsList.length > 0 ? (
          visitsList?.map((each) => {
            return (
              <Accordion className="mb-3" expanded={each.visit_id == visit_Id}>
                <AccordionSummary
                  className="fw-700 fs-16 bg-secndry"
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() => {
                    setVisit_Id(
                      each.visit_id == visit_Id ? null : each.visit_id
                    );
                  }}
                >
                  {each.patient_type}
                  <span className="ms-auto me-3">
                    {/* <Link to={`/Form/${each.visit_id}`}>
                      <EditNote className="fs-1 ms-2" />
                    </Link> */}
                    {moment(each.date).format("YYYY-MM-DD")}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={userRole && userRole === "4" && "h-500"}>
                    {userRole && userRole === "4" && (
                      <div className="text-primry">
                        <ProfileRow label="Fever" text={profileInfo.fever} />
                        <ProfileRow
                          label="Fever For"
                          text={profileInfo.fever_for}
                        />
                        <ProfileRow
                          label="Fever Level"
                          text={profileInfo.fever_level}
                        />
                        <ProfileRow
                          label="Nights Sweats"
                          text={profileInfo.nights_sweats}
                        />
                        <ProfileRow label="cough" text={profileInfo.cough} />
                        <ProfileRow
                          label="Coughing For"
                          text={profileInfo.cough_frq}
                        />
                        <ProfileRow
                          label="Any Medication"
                          text={profileInfo.medicine_history}
                        />
                        <ProfileRow
                          label="Test Name"
                          text={profileInfo.test_name}
                        />
                        <ProfileRow
                          label="Xpert Infection"
                          text={profileInfo.xpert_infection}
                        />
                        <ProfileRow
                          label="Test Result"
                          text={profileInfo.test_result}
                        />{" "}
                        <ProfileRow
                          label="Shortness Of Breath"
                          text={profileInfo.shortness_of_breath}
                        />
                        <ProfileRow
                          label="Shortness Of Breath For"
                          text={profileInfo.shortness_of_breath_for}
                        />
                        <ProfileRow
                          label="Loss Of Appetite"
                          text={profileInfo.loss_appetite}
                        />
                        <ProfileRow
                          label="Weight-Loss"
                          text={profileInfo.weight_loss}
                        />
                      </div>
                    )}
                    {/* <ProfileRow
                      label="Created At"
                      text={
                        profileInfo?.created_at &&
                        moment(profileInfo.created_at).format("YYYY-MM-DD")
                      }
                    /> */}
                    {/* <ProfileRow
                      label="Patient Id"
                      text={profileInfo.p_id}
                      copyText={true}
                    /> */}
                    {/* <ProfileRow
                      label="Name"
                      text={
                        userRole && userRole === "4"
                          ? profileInfo.username
                          : profileInfo.name
                      }
                    /> */}
                    {/* <ProfileRow
                      label="Diagnosis-Date"
                      text={moment(
                        profileInfo.initial_diagnose.format("YYYY-MM-DD")
                        )}
                    /> */}
                    {!process.env.REACT_APP_ANALYST && (
                      <ProfileRow
                        label="Patient Type"
                        text={profileInfo.patient_type}
                      />
                    )}
                    {!process.env.REACT_APP_ANALYST && (
                      <ProfileRow
                        label="Diagnosis"
                        text={profileInfo.tb_type}
                      />
                    )}
                    {/* <ProfileRow
                      label="Last Cough Date"
                      text={
                        profileInfo.last_cough &&
                        moment(profileInfo.last_cough).format("YYYY-MM-DD")
                      }
                    /> */}
                    <Snackbar
                      open={open}
                      autoHideDuration={3000}
                      onClose={handleCloseSnackbar}
                      message={`Copied:  ${copiedText}`}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      severity="success"
                    />
                    <ProfileRow label="DOB/Age" text={profileInfo.age} />
                    <ProfileRow label="Gender" text={profileInfo.gender} />
                    {userRole && userRole === "4" && (
                      <>
                        <ProfileRow
                          label="Blood In Cough"
                          text={profileInfo.coughing_blood}
                        />
                        <ProfileRow
                          label="Fever Time"
                          text={profileInfo.fever_time}
                        />
                        <ProfileRow
                          label="Wheezing"
                          text={profileInfo.wheezing}
                        />
                        <ProfileRow
                          label="Feel Mucus"
                          text={profileInfo.feel_mucus}
                        />
                        <ProfileRow
                          label="Smoking History"
                          text={profileInfo.smoking_history}
                        />
                        <ProfileRow
                          label="Medical Conditions"
                          text={profileInfo.medical_conditions}
                        />
                        <ProfileRow
                          label="Family Medical History"
                          text={profileInfo.family_medical_history}
                        />
                        {/* <ProfileRow label="Phone" text={profileInfo.phone} /> */}
                        <ProfileRow
                          label="In Contact With Infected People"
                          text={profileInfo.contact_with_infected}
                        />
                        <ProfileRow label="Height" text={profileInfo.height} />
                        <ProfileRow label="Weight" text={profileInfo.weight} />
                        <ProfileRow
                          label="Education Level"
                          text={profileInfo.education_level}
                        />
                        <ProfileRow
                          label="Profession"
                          text={profileInfo.profession}
                        />
                        <ProfileRow
                          label="Marital Status"
                          text={profileInfo.marital_status}
                        />
                        <ProfileRow
                          label="Occupants In Patient's Room"
                          text={profileInfo.occupants_in_one_rooms}
                        />
                        <ProfileRow
                          label="Is House Ventilated"
                          text={profileInfo.ventilated}
                        />
                        <ProfileRow
                          label="Volunteer Cough"
                          text={profileInfo.volunteer_cough}
                        />
                        <ProfileRow
                          label="AFB Sample"
                          text={profileInfo.afb_grading}
                        />
                        <ProfileRow
                          label="Test Date"
                          text={profileInfo.test_date}
                        />
                        {/* <ProfileRow
                          label="no_of_rooms"
                          text={profileInfo.no_of_rooms}
                        />
                        <ProfileRow
                          label="no_of_residents"
                          text={profileInfo.no_of_residents}
                        /> */}
                      </>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <>
            <Skeleton width="100%" height={60} />
            <Skeleton width="100%" height={60} />
            <Skeleton width="100%" height={60} />
          </>
        )}
      </div>
    </>
  ) : (
    <div className="graph-cards mb-4">
      <p className="fs-20 fw-700 text-blue poppins-Med">Patient Info</p>
      {Array.from({ length: 10 })?.map((_, index) => (
        <ProfileRow
          key={index}
          label={<Skeleton height={30} />}
          text={<Skeleton height={30} />}
        />
      ))}
    </div>
  );
}
