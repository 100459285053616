import React, { useState, useEffect } from "react";
import { fetchCsvData } from "../../API/AudioData";
import TableComponent from "../../Atoms/TableComponent";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { MusicNote, Download, PlayArrow, Stop } from "@mui/icons-material";

export default function CovidData() {
  const [covidData, setCovidData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [playingAudio, setPlayingAudio] = useState(null);

  useEffect(() => {
    loadData();
    // Cleanup audio on component unmount
    return () => {
      if (playingAudio) {
        playingAudio.pause();
        playingAudio.currentTime = 0;
      }
    };
  }, []);

  const loadData = async () => {
    try {
      const data = await fetchCsvData();
      // Limit to first 895 records and add index
      const limitedData = data.slice(0, 895).map((item, index) => ({
        ...item,
        sNo: index + 1,
      }));
      setCovidData(limitedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error loading COVID data:", error);
      setIsLoading(false);
    }
  };

  const handlePlayAudio = (filename) => {
    if (playingAudio) {
      playingAudio.pause();
      playingAudio.currentTime = 0;
    }

    const audioPath = `/audio/${filename}`;
    const audio = new Audio(audioPath);
    audio.play();
    setPlayingAudio(audio);

    // Stop playing when audio ends
    audio.addEventListener("ended", () => {
      setPlayingAudio(null);
    });
  };

  const handleStopAudio = () => {
    if (playingAudio) {
      playingAudio.pause();
      playingAudio.currentTime = 0;
      setPlayingAudio(null);
    }
  };

  const handleDownload = (filename) => {
    const audioPath = `/audio/${filename}`;
    const link = document.createElement("a");
    link.href = audioPath;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Add defaultColDef configuration
  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    sortable: true,
  };

  // Update gridOptions
  const gridOptions = {
    suppressColumnVirtualisation: false,
    domLayout: "autoHeight",
    suppressHorizontalScroll: false,
    enableCellTextSelection: true,
    alwaysShowHorizontalScroll: false,
    alwaysShowVerticalScroll: false,
    suppressScrollOnNewData: true,
    onFirstDataRendered: (params) => {
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    getRowHeight: () => 80,
  };

  const columnDefs = [
    {
      headerName: "S.No",
      field: "sNo",
      sortable: true,
      maxWidth: 100,
      flex: 0.5,
    },
    {
      headerName: "Date",
      field: "date",
      filter: "agDateColumnFilter",
      flex: 1,
    },
    {
      headerName: "Corona Test",
      field: "corona_test",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      flex: 1,
    },
    {
      headerName: "Age",
      field: "age",
      filter: "agNumberColumnFilter",
      maxWidth: 120,
      flex: 0.7,
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      maxWidth: 130,
      flex: 0.7,
    },
    {
      headerName: "Medical History",
      field: "medical_history",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      flex: 1.5,
    },
    {
      headerName: "Smoker",
      field: "smoker",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      maxWidth: 130,
      flex: 0.7,
    },
    {
      headerName: "Reported Symptoms",
      field: "patient_reported_symptoms",
      filter: "agTextColumnFilter",
      flex: 2,
    },
    {
      headerName: "Audio File",
      field: "cough_filename",
      filter: false,
      sortable: false,
      flex: 1.5,
      cellRenderer: (params) => {
        if (!params.value) return "No file";

        const isPlaying =
          playingAudio && playingAudio.src.includes(params.value);

        return (
          <div className="d-flex align-items-center gap-2">
            <MusicNote className="text-primary" />
            <span className="me-2">{params.value}</span>
            <Tooltip title={isPlaying ? "Stop" : "Play"}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  isPlaying ? handleStopAudio() : handlePlayAudio(params.value);
                }}
                className={isPlaying ? "text-danger" : "text-success"}
              >
                {isPlaying ? <Stop /> : <PlayArrow />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload(params.value);
                }}
                className="text-primary"
              >
                <Download />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      headerName: "Audio Player",
      field: "cough_filename",
      filter: false,
      sortable: false,
      flex: 2,
      cellRenderer: (params) => {
        if (!params.value) return "No audio available";

        return (
          <div className="d-flex align-items-center justify-content-center w-100 p-2">
            <audio
              controls
              style={{ height: "40px", width: "100%" }}
              onClick={(e) => e.stopPropagation()}
            >
              <source
                src={`${process.env.REACT_APP_RECORDING_BASE_URL}/${params.value}`}
                type="audio/wav"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="row container mx-auto pt-3">
      <h1 className="mt-3 mb-0 text-blue">SARS-CoV-2 Datas</h1>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: "calc(100vh - 150px)",
          overflow: "hidden",
        }}
      >
        <TableComponent
          value="covidData"
          Data={covidData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          floatingFilter={true}
          sendingReq={false}
          details={true}
          pagination={true}
          paginationPageSize={10}
          rowHeight={80}
          style={{ height: "100%" }}
        />
      </div>
    </div>
  );
}
