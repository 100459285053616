import React, { Fragment, useEffect, useState } from "react";
import ProfileCard from "../../Atoms/ProfileCard";
import RecordingsCard from "../../Atoms/RecordingsCard.js";
import Box from "@mui/material/Box";
import FiltersCard from "../../Atoms/FiltersCard";
import AnnotationCard from "../../Atoms/AnnotationCard";
import DispImgesCard from "../../Atoms/DispImgesCard";
import GetData from "../../API/GetData";
import PortalGet from "../../API/PortalGet";
import { Alert, Hidden, Snackbar } from "@mui/material";
import { CleaningServices, RemoveRedEye } from "@mui/icons-material";
import DailyAnnotatedChart from "../../Atoms/DailyAnnotatedChart.js";

export default function Index(props) {
  const { setprofileInfo, profileInfo, patientId, setpatientId } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [facilityId, setfacilityId] = useState("0");
  const [recordingsList, setrecordingsList] = useState([]);
  const [isLoading, setisLoading] = useState();
  const [fetchUrl, setfetchUrl] = useState();
  const [fileName, setfileName] = useState();
  const [IsloadingSearch, setIsloadingSearch] = useState(false);
  const [LoadingRecords, setLoadingRecords] = useState(false);
  const [fetchImgId, setfetchImgId] = useState();
  const [AnnComment, setAnnComment] = useState();
  const [selectedOpt, setselectedOpt] = useState([]);
  const [panImages, setpanImages] = useState();
  const [audioUrl, setAudioUrl] = useState();
  const [patientsList, setpatientsList] = useState([]);
  const [modelPredictions, setmodelPredictions] = useState([]);
  const [Refresh, setRefresh] = useState(false);
  const [AnnotationSubmitted, setAnnotationSubmitted] = useState(false);
  const [medicalStatus, setmedicalStatus] = useState([]);
  const userRole = localStorage.getItem("role");
  const [visitsList, setVisitsList] = useState([]);
  const [visit_Id, setVisit_Id] = useState();
  const [successMessage, setsuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [sendingReq, setSendingReq] = useState(false);
  const [annotation_label, setAnnotation_label] = useState([]);
  const [showAnnotationsChart, setshowAnnotationsChart] = useState(false);
  const [annotationChartData, setAnnotationChartData] = useState([]);
  const [isLoadingChart, setIsloadingChart] = useState(false);

  useEffect(() => {
    setpatientId(localStorage.getItem("patient_ID"));
    localStorage.setItem("patient_ID", null);
  }, []);

  useEffect(() => {
    if (visit_Id) {
      setSendingReq(true);
      const res = PortalGet.GetPatientProfileInfo(visit_Id);
      res.then((value) => {
        if (value !== undefined) {
          setprofileInfo(value.data);
        }
        setSendingReq(false);
      });
    }
  }, [visit_Id]);

  useEffect(() => {
    if (showAnnotationsChart) {
      setIsloadingChart(true);
      PortalGet.GetAnnotationsChart(
        facilityId,
        startDate,
        endDate,
        medicalStatus,
        annotation_label
      ).then((value) => {
        if (value !== undefined) {
          setAnnotationChartData(value.data);
        }
      });
    }
  }, [
    facilityId,
    startDate,
    endDate,
    medicalStatus,
    annotation_label,
    showAnnotationsChart,
  ]);

  useEffect(() => {
    setSendingReq(true);
    const res = PortalGet.GetPatientsList(
      facilityId,
      startDate,
      endDate,
      medicalStatus,
      annotation_label
    );
    res.then((value) => {
      if (value !== undefined) {
        setpatientsList(value.data);
        setIsloadingSearch(false);
      }
      setSendingReq(false);
    });
  }, [facilityId, startDate, endDate, medicalStatus, annotation_label]);

  useEffect(() => {
    console.log("patiennt id ==>>",patientId)
    if (patientId!==null) {
      console.log("patiennt in ==>>>>",patientId)
      setSendingReq(true);
      const res = GetData.GetVisitsById(patientId);
      res.then((value) => {
        if (value !== undefined) {
          setVisitsList(value.data);
        }
        setSendingReq(false);

      });
    }
  }, [profileInfo, patientId, Refresh]);

  useEffect(() => {
    if (visit_Id) {
      setLoadingRecords(true);
      setrecordingsList([]);
      setSendingReq(true);
      const res = GetData.GetRecordingsById(visit_Id);
      res.then((value) => {
        if (value !== undefined) {
          setrecordingsList(value.data);
        }
        setSendingReq(false);
        setLoadingRecords(false);
      });
    }
  }, [visit_Id, Refresh]);

  useEffect(() => {
    if (fetchUrl) {
      fetch(fetchUrl)
        .then((response) => response.blob())
        .then((blob) => {
          setAudioUrl(URL.createObjectURL(blob));
        })
        .catch((error) => {
          setErrorMessage("Error fetching audio:", error);
        });
      setisLoading(false);
    } else {
      setAudioUrl(null);
    }
  }, [fetchUrl]);

  useEffect(() => {
    if (fetchImgId) {
      // fetch(`https://dctapi.ai4lyf.com/model/panns/${fetchImgId}`)
      // fetch(`http://192.168.22.148:8080/model/panns/${fetchImgId}`)
      fetch(`${process.env.REACT_APP_API_URL || " https://dctapi.ai4lyf.com/"}model/panns/${fetchImgId}`)


        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok");
        })
        .then((data) => {
          setpanImages(data.reverse());
        })
        .catch((error) => {
          setErrorMessage("There was a problem fetching the images:", error);
        });
    }
  }, [fetchImgId]);

  return (
    <div className="secondary-bg pb-5">
      <Box className="container" sx={{ p: 3, paddingBottom: 0 }}>
        <div className="row gx-5">
          <div className="col-4 mb-3 h-100">
            <RecordingsCard
              setmodelPredictions={setmodelPredictions}
              setprofileInfo={setprofileInfo}
              profileInfo={profileInfo}
              recordingsList={recordingsList}
              setrecordingsList={setrecordingsList}
              isLoading={isLoading}
              setLoadingRecords={setLoadingRecords}
              LoadingRecords={LoadingRecords}
              setisLoading={setisLoading}
              fetchUrl={fetchUrl}
              setfetchUrl={setfetchUrl}
              fileName={fileName}
              setfileName={setfileName}
              fetchImgId={fetchImgId}
              setfetchImgId={setfetchImgId}
              AnnComment={AnnComment}
              setAnnComment={setAnnComment}
              selectedOpt={selectedOpt}
              setselectedOpt={setselectedOpt}
              Refresh={Refresh}
              setRefresh={setRefresh}
              userRole={userRole}
              visitsList={visitsList}
              setVisit_Id={setVisit_Id}
              visit_Id={visit_Id}
            />
            {localStorage.getItem("role") !== process.env.REACT_APP_DOCTOR && (
              <ProfileCard
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
                userRole={userRole}
                visitsList={visitsList}
                visit_Id={visit_Id}
                setVisit_Id={setVisit_Id}
              />
            )}
          </div>
          <div className="col-8 h-100">
            {/* <FiltersCard
              patientsList={patientsList}
              facilityId={facilityId}
              setfacilityId={setfacilityId}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setprofileInfo={setprofileInfo}
              profileInfo={profileInfo}
              isLoading={isLoading}
              setisLoading={setisLoading}
              fetchUrl={fetchUrl}
              setfetchUrl={setfetchUrl}
              fileName={fileName}
              setfileName={setfileName}
              fetchImgId={fetchImgId}
              setfetchImgId={setfetchImgId}
              AnnComment={AnnComment}
              setAnnComment={setAnnComment}
              selectedOpt={selectedOpt}
              setselectedOpt={setselectedOpt}
              setpatientId={setpatientId}
            /> */}
            <div className="h-100">
              <AnnotationCard
                userRole={userRole}
                Refresh={Refresh}
                setRefresh={setRefresh}
                modelPredictions={modelPredictions}
                patientsList={patientsList}
                facilityId={facilityId}
                setfacilityId={setfacilityId}
                fetchImgId={fetchImgId}
                recordingsList={recordingsList}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
                isLoading={isLoading}
                setisLoading={setisLoading}
                fetchUrl={fetchUrl}
                setfetchUrl={setfetchUrl}
                fileName={fileName}
                setfileName={setfileName}
                setfetchImgId={setfetchImgId}
                AnnComment={AnnComment}
                setAnnComment={setAnnComment}
                selectedOpt={selectedOpt}
                setselectedOpt={setselectedOpt}
                setpatientId={setpatientId}
                audioUrl={audioUrl}
                setAudioUrl={setAudioUrl}
                patientId={patientId}
                AnnotationSubmitted={AnnotationSubmitted}
                setAnnotationSubmitted={setAnnotationSubmitted}
                setmedicalStatus={setmedicalStatus}
                medicalStatus={medicalStatus}
                setIsloadingSearch={setIsloadingSearch}
                IsloadingSearch={IsloadingSearch}
                setAnnotation_label={setAnnotation_label}
                annotation_label={annotation_label}
              />
              <DispImgesCard
                patientId={patientId}
                panImages={panImages}
                setpanImages={setpanImages}
                setprofileInfo={setprofileInfo}
                profileInfo={profileInfo}
                isLoading={isLoading}
                setisLoading={setisLoading}
                fetchUrl={fetchUrl}
                setfetchUrl={setfetchUrl}
                fileName={fileName}
                setfileName={setfileName}
                fetchImgId={fetchImgId}
                setfetchImgId={setfetchImgId}
                AnnComment={AnnComment}
                setAnnComment={setAnnComment}
                selectedOpt={selectedOpt}
                setselectedOpt={setselectedOpt}
                AnnotationSubmitted={AnnotationSubmitted}
                setAnnotationSubmitted={setAnnotationSubmitted}
              />
            </div>
          </div>
        </div>
        <div className="row graph-cards mt-5">
          <button
            className="btn btn-primary col-1 ms-auto fs-14 p-3"
            onClick={() => setshowAnnotationsChart(!showAnnotationsChart)}
          >
            {showAnnotationsChart ? "Hide Chart" : "Show Chart"}
          </button>
          {showAnnotationsChart && (
            <DailyAnnotatedChart
              isLoadingChart={isLoadingChart}
              ApiData={annotationChartData}
            />
          )}
        </div>
      </Box>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage ? true : ErrorMessage ? true : false}
        // onClose={() => {
        //   setsuccessMessage("");
        //   setErrorMessage("");
        // }}
      >
        <Alert
          severity={ErrorMessage ? "warning" : successMessage && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {successMessage || ErrorMessage}
        </Alert>
      </Snackbar> */}
    </div>
  );
}
